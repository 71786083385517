import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import typesRoles from "@/shared/types/typesRoles";

const useLogin = () => {
  const store = useStore();
  const router = useRouter();
  const isLoading = ref(false);

  const fields = ref({ email: "", password: "" });

  const validateRoles = (role) => {
    const ROLE = typesRoles[role];
    let routeName = "";

    switch (ROLE) {
      case typesRoles[1]:
        routeName = "admin-dashboard";
        break;
      case typesRoles[2]:
        routeName = "admin-dashboard";
        break;
      case typesRoles[3]:
        routeName = "teacher-dashboard";
        break;
      case typesRoles[4]:
        routeName = "student-dashboard";
        break;
      default:
        break;
    }
    router.push({ name: routeName });
  };

  const onSubmit = async () => {
    isLoading.value = true;
    const { success, json } = await store.dispatch("auth/login", {
      ...fields.value,
    });
    isLoading.value = false;

    if (success) {
      if (json.has_random_password) {
        store.commit("shared/uiUserInfoModal", {
          isOpen: true,
        });
      }
      validateRoles(json.role);
    }
  };

  const loginByToken = async (token) => {
    isLoading.value = true;
    const { success, json } = await store.dispatch("auth/loginByToken", token);
    isLoading.value = false;

    if (success) {
      if (json.has_random_password) {
        store.commit("shared/uiUserInfoModal", {
          isOpen: true,
        });
      }
      validateRoles(json.role);
    }
  };

  const goPageRegister = () => {
    window.open("https://brightlingua.com/register", "_blank");
  };

  return {
    onSubmit,
    isLoading,
    fields,
    goPageRegister,
    loginByToken
  };
};

export default useLogin;
