<template>
  <div class="body">
    <h1 class="text-secondary-1 fw-bold text-center mb-1">Welcome!</h1>
    <p class="text-primary-0 text-center fw-bold">We’re so glad to see you</p>
    <div class="d-flex" style="margin-top:2rem; color:#eb661a;" v-if="autoLoginLoad">
      <i class="pi pi-spin pi-spinner m-auto" style="font-size: 2rem" ></i>
    </div>
    <form v-else class="d-flex flex-column gap-2 mt-4" @submit.prevent="onSubmit" autocomplete="off">
      <div class="field">
        <input-text
          v-model="fields.email"
          id="email"
          type="email"
          name="email"
          autocomplete="off"
          placeholder="Email"
          class="shadow-sm mb-3 text-primary-1 rounded-0"
          :class="fields.email.length > 10 ? 'input-fill' : ''"
          required
        />
      </div>
      <div class="field">
        <password
          autocomplete="off"
          v-model="fields.password"
          :feedback="false"
          name="password"
          placeholder="Password"
          toggleMask
          class="shadow-sm rounded-0"
          :class="fields.password.length > 0 ? 'input-fill' : ''"
          required
        />
      </div>
      <router-link :to="{ name: 'forgot-password' }">
        <button
          type="button"
          class="
            btn
            text-secondary-1 text-hover-secondary-1
            p-2
            px-5
            mb-2
            w-100
          "
        >
          Forgot your password?
        </button>
      </router-link>
      <button
        type="submit"
        class="
          btn
          text-white
          bl-shadow
          bg-primary-1 bg-hover-primary-1
          p-2
          px-5
          w-100
          mt-3
        "
        :disabled="isLoading"
      >
        Login
      </button>
      <div class="d-flex gap-1 align-items-center justify-content-center">
        <p>Need an account?</p>
        <button
          type="button"
          class="btn text-secondary-1 text-hover-secondary-1 p-2 px-2"
          @click="goPageRegister"
        >
          Register
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useLogin from "@/modules/public/composables/useLogin";
import { useRoute } from 'vue-router'
import { onBeforeMount, ref } from "vue";

export default {
  components: {},
  setup() {
    const route = useRoute()
    const { loginByToken } = useLogin()
    const autoLoginLoad = ref(false)

    onBeforeMount(async () => {
      if(route.query.token){
        autoLoginLoad.value = true
        const token = JSON.parse(window.atob(route.query.token))
        await loginByToken(token.token)
        autoLoginLoad.value = false
      }
    })

    return {
      ...useLogin(),
      autoLoginLoad
    };
  },
};
</script>
